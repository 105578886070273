<template>
  <div
    class="flex flex-middle select2-item-icon"
    :class="{ rtl: isLanguageRtl(), selected: selected }">
    <icon
      v-show="selected"
      icon="check"
      class="select2-item-icon__icon flex animate__fadeIn animate--fastest"></icon>

    <icon
      v-show="!selected"
      :icon="finalIcon"
      class="select2-item-icon__icon select-item-icon__icon--not-selected flex animate__fadeIn animate--fastest">
    </icon>

    <span>
      {{ safeTranslate(item.label) }}
    </span>
  </div>
</template>

<script>
import itemIconMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/select2/itemIcon.js";
// TODO: test this with this new mixin
export default {
  name: "IconItem",
  mixins: [itemIconMixin],
  props: {
    defaultIcon: {
      type: String,
      default: "chevron-inline-end",
    },
  },
  data: function () {
    return {};
  },
  computed: {
    directionAgnosticDefaultIcon() {
      if (this.defaultIcon != "triangle-right") {
        return this.defaultIcon;
      }
      return this.isLanguageRtl ? "triangle-left" : "triangle-right";
    },
    finalIcon() {
      // use our icon, if provided
      if (this.item && this.item.icon && this.item.icon !== "") {
        return this.item.icon;
      }

      // use default icon if able
      if (this.defaultIcon !== "") {
        return this.directionAgnosticDefaultIcon;
      }

      // we have no icon. use hard coded default
      return this.isLanguageRtl ? "triangle-left" : "triangle-right";
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.select2-item-icon {
  padding: calc(var(--margin-m) * 0.25) calc(var(--margin-m) * 0.25);
  margin-bottom: calc(var(--margin-m) * 0.75);
  background: var(--c-bg);
  border-inline-start: 4px solid var(--c-gray);
  transition: padding 150ms ease-out 0ms, border-color 150ms ease-out;

  &.selected,
  &.selected:hover {
    border-inline-start-color: var(--c-lead);
    font-weight: 500;
  }

  &:hover {
    border-inline-start-color: var(--c-lead);

    .select2-item-icon__icon {
      margin-inline-start: var(--margin-xs);
      margin-inline-end: var(--margin-s);
    }
  }

  &.highlight,
  &.highlight:hover {
    padding-inline-start: calc(var(--margin-m) * 0.3);
    border-inline-start-color: var(--c-lead);

    .select2-item-icon__icon {
      margin-inline-end: calc(var(--margin-m) * 0.35);
    }
  }
}

.select2-item-icon__icon {
  display: flex;
  margin-inline-end: calc(var(--margin-m) * 0.2);
  min-height: calc(var(--margin-m) * 1.5);
  transition: margin 150ms ease-out 90ms;
}
</style>
