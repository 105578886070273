export default {
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: {},
    },

    autoTranslate: {
      type: Boolean,
      default: true,
    },
    defaultIcon: {
      type: String,
      default: "triangle-right",
    },
  },
  data: function () {
    return {};
  },
  computed: {
    directionAgnosticDefaultIcon() {
      if (this.defaultIcon != "triangle-right") {
        return this.defaultIcon;
      }
      return this.isLanguageRtl ? "triangle-left" : "triangle-right";
    },
    finalIcon() {
      // use our icon, if provided
      if (this.item.icon && this.item.icon !== "") {
        return this.item.icon;
      }

      // use default icon if able
      if (this.defaultIcon !== "") {
        return this.directionAgnosticDefaultIcon;
      }

      // we have no icon. use hard coded default
      return this.isLanguageRtl ? "triangle-left" : "triangle-right";
    },
  },
};
